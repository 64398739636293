import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: 'us-east-2',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_tCZniwBIQ',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '2r0s3p0vgqvsi7fu09nepsebf9',


        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        
        federationTarget: "COGNITO_USER_POOLS",

        oauth: {
            domain: "dashfit.auth.us-east-2.amazoncognito.com",
            redirectSignIn: "https://dashfit.co/app",
            scope: ['phone', 'email', 'profile', 'openid'],
            redirectSignOut: "https://dashfit.co/logout",
            responseType: "TOKEN",
        }
    }
});

const currentConfig = Auth.configure();