// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCustomToken,onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyADoUjIxBmqTg7f2LUH9p961bsptqpAJAI",
  authDomain: "dashfit.firebaseapp.com",
  projectId: "dashfit",
  storageBucket: "dashfit.appspot.com",
  messagingSenderId: "683163169157",
  appId: "1:683163169157:web:610147d75295403195d4ec",
  measurementId: "G-KW5NBHLF58"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebaseApp = app;
export const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    // ...
  } else {
    // User is signed out
    // ...
  }

  const usr = auth.currentUser;
  console.log(auth);
  
  signInWithCustomToken(auth, 'dc6M2Ew4SheJtDm2gPOQal:APA91bEMD14-kFGvqT9dr0wOSUCyDwJs_2gm5IOZQiDVRPOed8fHp8eyd2jfycYY4DwU_WE_NxQuJanFzznKWCrhEGsv_MhqBpNjvasxX9sF_ssChWuiM6jLBu2M5juFb_Ml-rXzsPBS')
    .then((userCredential) => {
      // Signed in
      var user = userCredential.user;
      console.log(user)
  
      // ...
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorCode, errorMessage);
  
      // ...
    });
});


