import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Select } from '@windmill/react-ui'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableFooter,
  TableCell,
} from '@windmill/react-ui'
import { Button } from '@windmill/react-ui'
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import ExchangeTableRow from "components/exchange/ExchangeTableRow";

export default ({
  setActiveTab
}) => {

  const activeExchanges = [
    "Binance",
    "Kucoin",
    "Gate.io",
  ];
  const [exchangeCredentials, setExchangeCredentials] = useState([]);
  const [rows, setRows] = useState([<ExchangeTableRow 
    activeExchanges={activeExchanges} 
    setExchangeCredentials={setExchangeCredentials}
    activeExchanges={activeExchanges} />])


  const addRow = () => {
    if (rows.length >= activeExchanges.length) {
      return
    }
    setRows([...rows, 
    <ExchangeTableRow 
      activeExchanges={activeExchanges} 
      setExchangeCredentials={setExchangeCredentials}
      exchangeCredentials={exchangeCredentials}/>])
  }

  const removeRow = () => {
    if (rows.length <= 1) {
      return
    }
    let formValues = [...rows];
    setRows(formValues.slice(0, -1));
  }

  const nextButton = () => {
    setActiveTab("Notifications");
  }

  return (
    <div class="max-w-6xl mx-auto px-8 py-8">
      <TableContainer>
        <Table>
            {rows}
        </Table>
        <TableFooter>
        <div class="flex justify-end items-stretch space-x-4">
          <Button icon={MinusIcon} aria-label="Remove" onClick={removeRow}/>
          <Button icon={PlusIcon} aria-label="Add" onClick={addRow}/>
          </div>
        </TableFooter>
      </TableContainer>
      <Button onClick={nextButton}>Next</Button>
    </div>
  );

}