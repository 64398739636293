import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Modal from "react-modal";
import '@aws-amplify/ui-react/styles.css';
import axios from 'axios';
import 'api/interceptor';
import "./firebase/index"
import { currentConfig } from "./helpers/config";
import { Windmill } from '@windmill/react-ui'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

Modal.setAppElement("#root");
axios.defaults.baseURL = "https://7951z1dnw8.execute-api.eu-west-2.amazonaws.com/dev"

ReactDOM.render(
  <Windmill>
    <App />
  </Windmill>,
  document.getElementById("root")
);
