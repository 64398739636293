import React from "react";
import { useState, useEffect } from 'react'
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import Lottie from 'react-lottie';
import Header from "../headers/light.js";
import animationData from '../../lottie/notif_half.json';
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/telegramnotif.svg";
import CustomersLogoStripImage from "../../images/exchanges.png";
import Subscribe from "components/cta/Subscribe.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-100`}
  }
`;

const StyledLottie = styled(Lottie)`
  ${tw`min-w-0 w-full max-w-lg xl:max-w-3xl mt-16 lg:mt-24`}
`;

export default ({ roundedHeaderButton }) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [formData, setFormData] = useState({
    email: ""
  })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitted) {
      fetch("https://7951z1dnw8.execute-api.eu-west-2.amazonaws.com/dev/subscribe", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: JSON.stringify({ ...formData })
      })
        .then(() => alert("Success!"))
        .then(() => setIsSubmitted(false))
        .then(() => setFormData({ name: "", email: "", message: "" }))
        .catch(error => alert(error))
    }
  }, [errors, formData, isSubmitted])
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            {/* <Heading>
            Track instant changes of cryptocurrency wallet balances across <span tw="text-primary-500">multiple exchanges.</span>
            </Heading> */}
            <Heading>
              Dashfit is the <span tw="text-primary-500">key</span> to staying informed of balance changes in your <span tw="text-primary-500">crypto portfolio.</span>
            </Heading>
            <Paragraph>
              Track cryptocurrency investments across multiple exchanges. Dashfit offers real-time updates and historical data to assist with investment decisions.
            </Paragraph>
            <Subscribe></Subscribe>
            <CustomersLogoStrip>
              {/* <img src={CustomersLogoStripImage} alt="Our Customers" /> */}
            </CustomersLogoStrip>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              {/* <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" /> */}
              <StyledLottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
                style={{
                  display: "flex",
                  height: "70%",
                  width: "70%",
                  marginBottom: "150px",
                }}
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
