import axios from 'axios'


const getNotificationToken = async () => {
    return await axios.get("notification");
}

const saveTelegramNotification = async (email) => {
    let data = {
        email: email
    }
    return await axios.post("notification", data);
}

const saveEmailNotification = async (email) => {
    let data = {
        email: email
    }
    return await axios.post("notification/email", data);
}

const notification = {
    getNotificationToken, saveTelegramNotification, saveEmailNotification
}

export default notification;