import React from "react";
import { useState, useEffect } from 'react'
import styled from "styled-components";
import tw from "twin.macro";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from '@windmill/react-ui'
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TelegramIcon } from "images/telegram-blue.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default function Subscribe() {
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    function closeModal() {
        setIsModalOpen(false)
    }

    const handleSubmit = e => {
        setErrors(validate(formData))
        setIsSubmitted(true)
        e.preventDefault();
    }
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        email: ""
    })
    const validate = (formData) => {
        let formErrors = {}
        if (!formData.email) {
            formErrors.email = "Email required"
        }
        return formErrors
    }
    const handleChange = e => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitted) {
            fetch("https://7951z1dnw8.execute-api.eu-west-2.amazonaws.com/dev/subscribe", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: JSON.stringify({ ...formData })
            })
                .then(() => setIsModalOpen(true))
                .then(() => setIsSubmitted(false))
                .then(() => setFormData({ name: "", email: "", message: "" }))
                .catch(error => alert(error))
        }
    }, [errors, formData, isSubmitted])

    return (
        <div>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <ModalHeader>Thank you!</ModalHeader>
                <ModalBody>
                    Thank you for your interest in dashfit.co!
                    Want to get in sooner? Gain early access by following us on social media!

                </ModalBody>
                <ModalFooter className="flex items-center justify-center">
                    <SocialLinksContainer>
                        <SocialLink href="https://www.facebook.com/Dashfitco-110505118156134" target="_blank" >
                            <FacebookIcon />
                        </SocialLink>
                        <SocialLink href="https://twitter.com/dashfitco" target="_blank">
                            <TwitterIcon />
                        </SocialLink>
                        {/* <SocialLink href="https://t.me/dashfitco" target="_blank">
                            <TelegramIcon />
                        </SocialLink> */}
                        <SocialLink href="https://www.youtube.com/channel/UCVRsQBKHTau9Ri8TdFQqzog" target="_blank">
                            <YoutubeIcon />
                        </SocialLink>
                    </SocialLinksContainer>
                </ModalFooter>
            </Modal>
            <Actions>
                <form name="contact" method="POST" onSubmit={handleSubmit}>
                    <input type="hidden" name="form-name" value="contact" />
                    <input type="email" name="email" placeholder="Your E-mail Address" onChange={handleChange} />
                    <button type="submit">Sign Up for Free</button>
                </form>
            </Actions>
        </div>
    );
}
