import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import "styles/tailwind.output.css";
import React, { Suspense, lazy, useEffect } from "react";
import { SidebarProvider } from './context/SidebarContext'
import ThemedSuspense from './components/dashboard/ThemedSuspense'
import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "demos/SaaSProductLandingPage.js";
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginAws from "pages/LoginAws";
import SignupAws from "pages/LoginAws";
import Installer from "components/cards/Installer";
import Pricing from "pages/Pricing";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Terms from "pages/TermsOfService";
const Layout = lazy(() => import('./components/dashboard/Layout'))

export default function App() {
  return (
    <Router>
      {/* <AccessibleNavigationAnnouncer /> */}
      <Switch>
        <Route path="/sign-up">
          <SignupAws />
        </Route>
        <Route path="/login">
          <LoginAws />
        </Route>
        <Route path="/components/:type/:subtype/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/components/:type/:name">
          <ComponentRenderer />
        </Route>
        <Route exact path="/">
          <MainLandingPage />
        </Route>
        <Route exact path="/installer">
          <Installer />
        </Route>
        <Route exact path="/pricing">
          <Pricing />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <SidebarProvider>
          <Suspense fallback={<ThemedSuspense />}>
            <Route path="/app" component={Layout} />
          </Suspense>
        </SidebarProvider>
      </Switch>
    </Router>
  );
}