import axios from 'axios'


const getPricing = async () => {
    return await axios.get("pricing");
}

const pricing = {
    getPricing
}

export default pricing;