import React from "react";
import {
    TableRow,
    TableCell,
} from '@windmill/react-ui'
import tw from "twin.macro";
import styled from "styled-components";
import { Card, CardBody } from '@windmill/react-ui'
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-3-icon.svg";
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-white border border-gray-200 placeholder-gray-600 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`select-none cursor-pointer w-full px-8 py-4 rounded-lg font-medium bg-white border border-gray-200 placeholder-gray-600 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Button = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
export default ({ activeExchanges = [], exchangeCredentials=[], setExchangeCredentials }) => {

    const [exchange, setExchange] = React.useState(activeExchanges[0]);
    const [saved, setSaved] = React.useState(false);
    const [credentials, setCredentials] = React.useState({});
    
    const onSave = () => {
        setSaved(true)
    }

    const updateExchangeCredentials = (creds) => {
        let newValue = {[exchange]: creds}
        let value = [...exchangeCredentials]
        if (value[exchange]) {
            delete value[exchange]
        }
        value[exchange] = creds
        setExchangeCredentials(value)
        //setExchangeCredentials(exchangeCredentials => [...exchangeCredentials, newValue]);
        console.log(exchangeCredentials)
        // let updated = exchangeCredentials.forEach(cred => {
        //     if (cred.exchange === exchange) {
        //         return null;
        //     }
        //     return cred;
        // })

        // if (!updated || updated.length < 1) {
        //     setExchangeCredentials([creds]);
        // } else {
        //     updated.push(creds);
        //     setExchangeCredentials(updated);
        // }
    }

    const onCredentialsChange = (e) => {
        const { name, value } = e.target
        let creds = {
            ...credentials,
            [name]: value
        }
        setCredentials(creds);
        updateExchangeCredentials(creds)
        // setCredentials(creds)
        // updateExchangeCredentials(creds);
    }

    const onExchangeNameChanged = (e) => {
        const { value } = e.target
        const val = exchangeCredentials[exchange]
        //arrayObj[value] = val
        //delete arrayObj[exchange]
        //setExchangeCredentials(arrayObj);
        setExchange(value);
    }

    return (
        <Card colored className="flex bg-gray-100 justify-center border border-white">
            <CardBody>
                <TableRow>
                    <TableCell>
                        <Select className="mt-5" value={exchange} name="exchange" onChange={(e) =>onExchangeNameChanged(e)}>
                            {activeExchanges.map((exchange, index) => (
                                <option key={index}>{exchange}</option>
                            ))}
                        </Select>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Input type="text" placeholder="API Key" name="apiKey" onChange={onCredentialsChange}/>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Input type="text" placeholder="Secret Key" name="secretKey" onChange={onCredentialsChange}/>
                    </TableCell>
                </TableRow>
            <Button block icon={saved ? <ArrowRightIcon /> : <ArrowRightIcon />} onClick={onSave}>{saved ? "Saved" : "Save"}</Button>
            </CardBody>
        </Card>
    )
}