import React, { useEffect } from "react";
import { Heading, View, Image, Text, Button, Authenticator, useAuthenticator, useTheme } from '@aws-amplify/ui-react';
import logo from "images/dashfit-blue.png";
import {Hub} from "aws-amplify";
import Auth from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';

const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="Amplify logo"
            maxWidth="35%"
            height="35%"
            src={logo}
          />
        </View>
      );
    },
  
    Footer() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={`${tokens.colors.neutral['80']}`}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },
  
    SignIn: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },
  
    SignUp: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
  };

  export default function LoginAws() {
    let history = useHistory();
    useEffect(() => {
        Hub.listen("auth", ({payload: {event, data}}) => {
          switch (event) {
            case "signIn":
            case "cognitoHostedUI":
              console.log("signed in");
              Auth.currentSession().then(data => console.log(data.accessToken));
              history.push("app");
              break;
            case "signOut":
              history.push("/");
              break;
            case "signIn_failure":
            case "cognitoHostedUI_failure":
              console.log("Sign in failure", data);
              break;
          }
        });
      }, []);

        Auth.currentAuthenticatedUser()
          .then(user => {
            history.push("app");
          });

    return (
      <Authenticator components={components} socialProviders={['google']} />
    );
  }
  