import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import notification from "../../api/notification";

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const DisabledSubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-gray-500 text-white w-full py-4 rounded-lg hover:bg-gray-400 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none cursor-not-allowed`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Submit = styled.button`
  ${tw`mt-5  font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900  duration-300 flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;


const TelegramButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-blue-600 text-white w-full py-4 rounded-lg hover:bg-blue-900 hover:text-white transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;


export default ({
  activeTab, setActiveTab
  }) => {
    const [activeMethod, setActiveMethod] = useState("Telegram");
    const [showNextButton, setShowNextButton] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [email, setEmail] = useState();

    const handleChange = (event) => {
      console.log(event.target.value);
      setActiveMethod(event.target.value);
    }

    const emailTextChange = (event) => {
      let valid = String(event.target.value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      setEmail(event.target.value);
      setSubmitButtonDisabled(!valid);
    }

    const submit = () => {
      notification.saveEmailNotification(email).then((info) => {
        setShowNextButton(true);
      });
      return null;
    }

    const nextButton = () => {
      setActiveTab("Overview");
    }

    const telegramButtonClick = (e) => {
      e.preventDefault();
      notification.getNotificationInfo().then((info) => {
      window.open("https://t.me/dashfit_bot?start=" + info.data.token, "_blank");
      });
      setShowNextButton(true);
      return null;
    }

    return (
        <Form>
          <Select className="mt-1" value={activeMethod} onChange={handleChange}>
            <option value="Telegram">Telegram</option>
            <option value="Email">E-Mail</option>
            <option value="Slack" disabled>Slack (Soon)</option>
            <option value="Discord" disabled>Discord (Soon)</option>
          </Select>
          {
            activeMethod === "Telegram" ? (
              <TelegramButton layout="outline" onClick={telegramButtonClick}>Start Telegram Bot</TelegramButton>
            ) : null
          }
                    {
            activeMethod === "Email" ? (
              <>
              <Input type="email" name="email" placeholder="Your E-mail Address" onChange={emailTextChange} />
              {!submitButtonDisabled ? <Submit type="button" onClick={submit} layout="outline">Submit</Submit> : <DisabledSubmitButton type="button" onClick={submit} layout="outline" disabled>Submit</DisabledSubmitButton>}
              </>
              ) : null
          }
          {
            showNextButton ? (<SubmitButton type="button" onClick={nextButton}>
            <span className="text">Next</span>
          </SubmitButton>) : null
          }
        </Form>
      );
}