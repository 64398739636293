import axios from 'axios'
import Auth from '@aws-amplify/auth';
const AUTH_HEADER = "X-Dashfit-Auth"


axios.interceptors.request.use(function (config) {
    try {
        document.getElementById("u-loading").style.display = "flex";
        document.getElementById("root").style.display = "none";
        //config.headers[AUTH_HEADER] = "5e302878-adf7-40f8-9ed6-5bc76b183c38"
        return Auth.currentSession().then(data => {
            console.log(data);
            config.headers[AUTH_HEADER] = data.accessToken.payload.username

            return config;
        }).catch(err => {
            console.log(err);
            return config;
        });
    } catch (e) {
        console.log(e)
        return config
    }
    
}, function (error) {
    document.getElementById("root").style.display = "inline";
    document.getElementById("u-loading").style.display = "none";
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    try {
        return response;
    } catch (e) {
        console.log(e)
        return response;
    }

}, function (error) {
    document.getElementById("root").style.display = "inline";
    document.getElementById("u-loading").style.display = "none";
    return Promise.reject(error);
});
