import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import { Suspense, lazy, useEffect } from "react";
import pricing from "api/pricing";
import { getStripePayments, getProducts } from "@stripe/firestore-stripe-payments";
import { createCheckoutSession } from "@stripe/firestore-stripe-payments";
import { signInWithCustomToken } from 'firebase/auth';
import { auth, firebaseApp } from "../firebase/index";
import 'firebase/compat/auth';

export default () => {
  const [pricingData, setPricingData] = React.useState(null);

  const loginUser = async () => {
    signInWithCustomToken(auth, 'dc6M2Ew4SheJtDm2gPOQal:APA91bEMD14-kFGvqT9dr0wOSUCyDwJs_2gm5IOZQiDVRPOed8fHp8eyd2jfycYY4DwU_WE_NxQuJanFzznKWCrhEGsv_MhqBpNjvasxX9sF_ssChWuiM6jLBu2M5juFb_Ml-rXzsPBS')
    .then((userCredential) => {
      // Signed in
      var user = userCredential.user;
      console.log(user)
  
      // ...
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorCode, errorMessage);
  
      // ...
    });
  }

  useEffect(() => {
    loginUser()
    async function fetchMyAPI() {
      const payments = getStripePayments(firebaseApp, {
        productsCollection: "products",
        customersCollection: "customers",
      });
      console.log(payments);
  
  const session = await createCheckoutSession(payments, {
    price: 'myPriceId',
  });
  window.location.assign(session.url);
    }
    fetchMyAPI()
    pricing.getPricing().then(res => {
      console.log(res);
      setPricingData(res)
    })
  }, []);
  return (
    <AnimationRevealPage>
      <Pricing plans={pricingData}/>
    </AnimationRevealPage>
  );
};
